import React, { useContext, useEffect, useState } from "react";
import { useCountryContext } from "./countryContext";

const initialLangContextState = {
  lang: "nl" as Lang,
  setLang: () => {
    throw new Error("You forgot to wrap this in a LangContextProvider object");
  },
  langReady: false,
};

type Lang = "en" | "nl" | "fr";

interface ContextShape {
  lang: Lang;
  setLang: React.Dispatch<React.SetStateAction<Lang>>;
  langReady: boolean;
}

const LangContext = React.createContext<ContextShape>(initialLangContextState);

export const LangContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [lang, setLang] = useState<Lang>(initialLangContextState.lang);
  const [langReady, setLangReady] = useState(initialLangContextState.langReady);

  const { country, countryReady } = useCountryContext();

  useEffect(() => {
    if (country.toLowerCase() === "nl") {
      setLang("nl");
    } else {
      if (navigator?.languages?.length) {
        for (let l of navigator.languages) {
          let formattedLang = l.split("-")[0];
          if (["nl", "fr"].includes(formattedLang)) {
            setLang(formattedLang as Lang);
            break;
          }
        }
      }
    }

    setLangReady(true);
  }, [countryReady]);

  return (
    <LangContext.Provider
      value={{
        lang,
        setLang,
        langReady,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};

export const useLangContext = (): ContextShape => useContext(LangContext);
